/* SolutionsPage.css */
.solutions-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f9f9f9; /* Fondo de la página */
}

.solutions-section {
    margin-top: 40px;
}

.section-icon {
    font-size: 32px;
    margin-right: 10px;
}

.vehicle-solutions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center;
    
}

.vehicle-card {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff; /* Fondo de las tarjetas */
    transition: transform 0.2s; /* Efecto de transición al hacer hover */
    opacity: 0;
}

.vehicle-card:hover {
    transform: translateY(-5px); /* Efecto de elevación al hacer hover */
}

.vehicle-icon {
    font-size: 64px;
    margin-bottom: 20px;
    color: #007bff; /* Color azul para los íconos */
}

.vehicle-card h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333; /* Color del título */
}

.vehicle-card p {
    font-size: 16px;
    color: #666; /* Color del texto */
}

.vehicle-card a {
    display: block;
    margin-top: 20px;
    color: #007bff; /* Color del enlace */
    text-decoration: none;
    font-weight: bold;
}

.vehicle-card a:hover {
    text-decoration: underline; /* Efecto de subrayado al hacer hover en el enlace */
}

/* Animación de aparición de las tarjetas */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Aplicamos la animación a las tarjetas */
.vehicle-card.animate {
    animation: fadeInUp 0.6s ease-in-out;
    opacity: 1; /* Añadimos esta declaración para que la tarjeta sea visible durante la animación */
}

.contact-section {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
  }
  
  .contact-section h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-section p {
    font-size: 16px;
    color: #666;
  }

  .cta-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }

  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  




