.slider-img {
    width: 100%;
    height: auto;
  }
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Esconde cualquier desbordamiento horizontal */
  }

  /* Agregar estilos para centrar el contenido en dispositivos móviles */
@media (max-width: 600px) {
  .MuiBox-root.text-container {
    text-align: center;
  }
}

.slick-dots li.slick-active button {
  background-color: #0056b3;
  opacity: 1;
}
    