/* AboutUsPage.css */

.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f7f7f7; /* Fondo de la página */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.section-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
    color: #007bff; /* Color azul para el título */
}

.intro-text {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.content {
    line-height: 1.6;
}

h3 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 16px;
    color: #007bff; /* Color azul para los subtítulos */
}

.contact-info {
    font-size: 16px;
    margin-top: 40px;
    font-style: italic;
    text-align: center;
}
