/* Footer.css */
.footer-container {
    background-color: #2d2d2d;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .footer-text {
    font-size: 18px;
    margin: 0;
  }
  
  .social-icons {
    display: flex;
    align-items: center;
  }
  
  .icon {
    font-size: 24px;
    margin: 0 8px;
    color: #fff;
    transition: transform 0.3s;
  }
  
  .icon:hover {
    transform: scale(1.2);
  }
  
  .social-icons a {
    text-decoration: none;
  }positionOdometer
  
  .social-icons a:not(:last-child) {
    margin-right: 20px;
  }
  
  /* Media Query para pantallas más pequeñas */
  @media (max-width: 480px) {
    .footer-content {
      flex-direction: column;
    }
    .social-icons {
      margin-top: 10px;
    }
  }

  .email-info {
    display: flex;
    align-items: center;
  }
  
  .email-link {
    color: #fff;
    text-decoration: none;
    margin-left: 8px;
    font-size: 16px;
  }