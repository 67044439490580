/* ContactUsPage.css */

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f2f2f2;
  }
  
  .contact-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact-info {
    max-width: 400px;
    text-align: center;
  }
  
  .contact-method {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .contact-icon {
    font-size: 24px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
  }
  
  .contact-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-link {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .contact-link:hover {
    color: #0056b3;
  }
  