/* TextContainerComponent.css */

/* Estilos para el contenedor de texto */
.text-container-1 {
    font-size: 24px;
    padding: 16px;
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 600px) {
    .text-container-1 {
      font-size: 10px; /* Ajusta el tamaño de la fuente según tus necesidades */
      padding: 8px; /* Ajusta el espaciado según tus necesidades */
    }
  }
  